import { DatePicker, DatePickerProps } from '@/components/Input/DatePicker'
import { css } from '@emotion/react'
import { EventTracker } from '@lifedot/tracking'
import { lifedotDirectNumber } from '@lifedot/constants/phoneNumbers'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { FieldValues } from 'react-hook-form'
import { useMinReservableDate } from './use-min-researveble-date'
import { isTELReceptionDate } from './dependencies'

const styles = {
  link: css({
    display: 'inline-grid',
    gap: 4,
    textDecoration: 'none',
    lineHeight: '1',
    [mq('sp')]: {
      gap: 2
    },
    '::after': {
      content: '"TEL"',
      color: palette.accent02,
      fontSize: 12,
      fontWeight: 'bold',
      [mq('sp')]: {
        fontSize: 10
      }
    }
  })
}

interface TourDatePickerProps<T extends FieldValues> {
  name: DatePickerProps<T>['name']
  requestable: boolean
}

export const TourDatePicker = <T extends FieldValues = never>({
  name,
  requestable
}: TourDatePickerProps<T>) => {
  const minDate = useMinReservableDate(requestable)

  return (
    <DatePicker<T>
      name={name}
      dateFormat="yyyy年MM月dd日"
      dateFormatCalendar="yyyy年MM月"
      minDate={minDate}
      withPortal
      id={name}
      caption="※翌日のご予約の場合、霊園の定休日や営業時間の兼ね合いでご希望に沿えない場合がございます。"
      renderDayContents={(dayOfMonth, date) =>
        renderDayContents(requestable, dayOfMonth, date)
      }
    />
  )
}

const renderDayContents = (
  requestable: boolean,
  dayOfMonth: number,
  date?: Date
) => {
  if (isTELReceptionDate(requestable, date))
    return (
      <EventTracker label="reservingCalenderTel" action="click" clone>
        <a href={`tel:${lifedotDirectNumber}`} css={styles.link}>
          {dayOfMonth}
        </a>
      </EventTracker>
    )
  return <>{dayOfMonth}</>
}
