import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export const isTELReceptionDate = (requestable: boolean, date?: Date) => {
  if (dayjs(date).isToday()) return true
  if (!requestable && dayjs(date).isTomorrow()) return true
  return false
}
