import { Frame } from '@lifedot/components/Frame'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { cemeteryMainImage } from '@lifedot/utils'

const styles = {
  root: css({
    display: 'grid',
    gridTemplateColumns: '3fr 7fr',
    gap: 16,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8
    }
  }),
  img: css({
    width: 70,
    fontSize: 0,
    [mq('sp')]: {
      width: 60
    }
  }),
  name: css({
    fontWeight: 'bold'
  })
}

type CemeteryProps = {
  main_image: {
    readonly path: string | null
    readonly alt: string | null
  } | null
  cemeteryName: string
  prefectureName: string
  cityName: string
}

export const Cemetery: React.FC<CemeteryProps> = ({
  main_image,
  cemeteryName,
  prefectureName,
  cityName
}) => {
  return (
    <Frame
      leftContent={
        <figure css={styles.img}>
          <Image
            src={cemeteryMainImage(main_image).path}
            width={128}
            height={96}
            alt={main_image?.alt ?? cemeteryName}
            priority={true}
          />
        </figure>
      }
      rightContent={
        <>
          <p css={typography.textS}>
            {prefectureName} / {cityName}
          </p>
          <p css={[typography.textL, styles.name]}>{cemeteryName}</p>
        </>
      }
    />
  )
}
