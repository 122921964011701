import { useCallback } from 'react'
import { FieldByType } from '@/components/Input/FieldByType'
import {
  useFormContext,
  FieldValues,
  Controller,
  ControllerRenderProps
} from 'react-hook-form'
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import dayjs from 'dayjs'
import JapaneseHolidays from 'japanese-holidays'
import { ReadOnlyInput } from './ReadOnlyInput'

registerLocale('ja', ja)

const WEEKEND = {
  SUNDAY: 0,
  SATURDAY: 6
}
const HOLIDAY_NAME = {
  SATURDAY: 'saturday',
  HOLIDAY: 'holiday'
}

const styles = {
  root: css({
    '.react-datepicker__header': {
      backgroundColor: palette.background,
      border: 'none',
      padding: '12px 0 8px'
    },
    '.react-datepicker__current-month': {
      color: palette.black
    },
    '.react-datepicker__navigation--next': {
      top: 13,
      right: 8
    },
    '.react-datepicker__navigation--previous': {
      top: 13,
      left: 8
    },
    '.react-datepicker__navigation-icon::before': {
      borderColor: palette.gray3
    },
    '.react-datepicker__month': {
      margin: '8px 16px 16px'
    },
    '.react-datepicker__day-name': {
      fontSize: 14,
      color: palette.black
    },
    '.react-datepicker__day': {
      fontSize: 16,
      color: palette.black
    },
    '.react-datepicker__day:hover': {
      backgroundColor: palette.background
    },
    '.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected':
      {
        backgroundColor: palette.main,
        color: palette.black
      },
    ['.' + HOLIDAY_NAME.SATURDAY]: css({
      color: '#3969E1'
    }),
    ['.' + HOLIDAY_NAME.HOLIDAY]: css({
      color: palette.alert
    }),
    '.react-datepicker__day--disabled, .react-datepicker__week .react-datepicker__day--outside-month':
      {
        color: palette.gray3
      },
    '.react-datepicker__day--disabled:hover': {
      background: 'none'
    },
    '.react-datepicker__month-container': {
      float: 'none'
    },
    '.react-datepicker__children-container': {
      width: '100%',
      margin: 0,
      padding: 0
    }
  }),
  caption: css({
    maxWidth: 400,
    padding: '0 16px 16px',
    color: palette.gray6,
    textAlign: 'justify',
    [mq('max', 400)]: {
      maxWidth: 312
    }
  })
}

export interface DatePickerProps<T extends FieldValues>
  extends Omit<
    ReactDatePickerProps,
    'onChange' | 'selected' | 'name' | 'id' | 'placeholderText'
  > {
  name: FieldByType<T, string>
  id: string
  caption?: string
}

export const DatePicker = <T extends FieldValues = never>({
  name,
  ...props
}: DatePickerProps<T>): ReturnType<React.FC> => {
  const { control } = useFormContext()

  return (
    <div css={styles.root}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => <RenderDatePicker field={field} {...props} />}
      />
    </div>
  )
}

interface RenderDatePickerProps<T extends FieldValues>
  extends Omit<ReactDatePickerProps, 'onChange' | 'selected'> {
  field: ControllerRenderProps<FieldValues, FieldByType<T, string>>
  caption?: string
}

const RenderDatePicker = <T extends FieldValues = never>({
  field,
  caption,
  ...props
}: RenderDatePickerProps<T>): ReturnType<React.FC> => {
  const makeWeekdayClassName = useCallback((date: Date) => {
    const day = dayjs(date).day()
    if (day === WEEKEND.SATURDAY) return HOLIDAY_NAME.SATURDAY
    if (day === WEEKEND.SUNDAY) return HOLIDAY_NAME.HOLIDAY
    return null
  }, [])
  const makeDayClassName = useCallback((date: Date) => {
    const day = dayjs(date).day()
    if (day === WEEKEND.SATURDAY) return HOLIDAY_NAME.SATURDAY
    if (day === WEEKEND.SUNDAY || JapaneseHolidays.isHoliday(date))
      return HOLIDAY_NAME.HOLIDAY
    return null
  }, [])
  const handleChange = useCallback(
    (date: Date | null) => {
      field.onChange(date)
    },
    [field]
  )

  return (
    <ReactDatePicker
      onChange={handleChange}
      selected={
        typeof field.value === 'string'
          ? dayjs(field.value).toDate()
          : field.value
      }
      locale="ja"
      calendarStartDay={1}
      weekDayClassName={makeWeekdayClassName}
      dayClassName={makeDayClassName}
      customInput={<ReadOnlyInput inputRef={field.ref} />}
      placeholderText="日付を選ぶ"
      {...props}
    >
      {caption && <p css={[typography.textS, styles.caption]}>{caption}</p>}
    </ReactDatePicker>
  )
}
