import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export const useMinReservableDate = (requestable: boolean) => {
  const [minDate, setMinDate] = useState<Date | null>(null)
  const nonReservationDays = requestable ? 1 : 2
  useEffect(() => {
    setMinDate(dayjs().startOf('day').add(nonReservationDays, 'day').toDate())
  }, [nonReservationDays])

  return minDate
}
