export const wantTimeTypes = [
  {
    value: 'unresolved',
    label: '未定'
  },
  {
    value: 'am',
    label: '午前'
  },
  {
    value: 'pm',
    label: '午後'
  },
  {
    value: '_9_00',
    label: '9:00'
  },
  {
    value: '_9_30',
    label: '9:30'
  },
  {
    value: '_10_00',
    label: '10:00'
  },
  {
    value: '_10_30',
    label: '10:30'
  },
  {
    value: '_11_00',
    label: '11:00'
  },
  {
    value: '_11_30',
    label: '11:30'
  },
  {
    value: '_12_00',
    label: '12:00'
  },
  {
    value: '_12_30',
    label: '12:30'
  },
  {
    value: '_13_00',
    label: '13:00'
  },
  {
    value: '_13_30',
    label: '13:30'
  },
  {
    value: '_14_00',
    label: '14:00'
  },
  {
    value: '_14_30',
    label: '14:30'
  },
  {
    value: '_15_00',
    label: '15:00'
  },
  {
    value: '_15_30',
    label: '15:30'
  },
  {
    value: '_16_00',
    label: '16:00'
  },
  {
    value: '_16_30',
    label: '16:30'
  }
]
