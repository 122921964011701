import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'

const inputStyles = {
  wrapper: css({
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 6px)',
      right: 16,
      width: 6,
      height: 6,
      borderBottom: `2px solid ${palette.gray4}`,
      borderRight: `2px solid ${palette.gray4}`,
      transform: 'rotate(45deg)',
      pointerEvents: 'none',
      [mq('max', 320)]: {
        right: 9
      }
    }
  }),
  input: css({
    width: '100%',
    color: palette.black,
    border: `2px solid ${palette.gray4}`,
    borderRadius: 4,
    padding: '12px 8px',
    cursor: 'pointer',
    background: 'none',
    '&::placeholder': {
      color: palette.black
    }
  })
}

type ReadOnlyInputProps = {
  inputRef: React.Ref<HTMLInputElement>
} & ComponentPropsWithoutRef<'input'>

export const ReadOnlyInput = forwardRef<HTMLInputElement, ReadOnlyInputProps>(
  function CustomInput(props, ref) {
    return (
      <div css={inputStyles.wrapper}>
        <input
          type="text"
          onClick={props.onClick}
          value={props.value}
          placeholder={props.placeholder}
          ref={props.inputRef}
          readOnly={true}
          id={props.id}
          css={inputStyles.input}
        />
      </div>
    )
  }
)
